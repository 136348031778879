import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Container from 'common/src/components/UI/ContainerTwo';
import BlogPost from 'common/src/components/BlogPost';
import SectionHeading from '../SectionHeading';
import Section, { ServiceWrapper } from './service.style';

const Service = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        Features {
          id
          title
          description
          link
          icon {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const services = data.saasJson.Features;
  return (
    <Section id="feature_section">
      <Container noGutter>
        <Zoom>
          <SectionHeading
            slogan="OUR FEATURES"
            title="Featured Service that We Provide"
          />
        </Zoom>
        <ServiceWrapper>
          {services.map(service => (
            <Fade key={service.id} up delay={100 * service.id}>
              <BlogPost
                className="serviceItem"
                thumbUrl={service.icon.childImageSharp.fluid.src}
                title={service.title}
                excerpt={<span dangerouslySetInnerHTML={{ __html: service.description }} />}
                link={service.link}
              />
            </Fade>
          ))}
        </ServiceWrapper>
      </Container>
    </Section>
  );
};

export default Service;
