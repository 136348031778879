import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from "react-icons-kit"
import { imac } from "react-icons-kit/ikons/imac"

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
// import Particles from '../Particle';
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
} from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  image,
  mobile,
}) => {
  const Data = useStaticQuery(graphql`
  query {
    BannerObject1 : file(relativePath: { eq: "image/saas/banner/bannerObject1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    BackgroundImage : file(relativePath: { eq: "image/saas/saas-banner.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`);

  const ButtonGroup = () => (
    <Fragment>
      <a href="https://demo.repairsetup.com">
        <Button title="VIEW DEMO" {...btnStyle}
          icon={<Icon icon={imac} size={24} />}
          iconPosition="left"
          onClick={() => {
            ReactGA.event({
              category: 'demo',
              action: 'Open'
            })
          }} />
      </a>
      <Link to="/docs">
        <Button
          className="outlined"
          title="EXPLORE MORE"
          variant="outlined"
          {...outlineBtnStyle}
        // onClick={() => {
        // const $anchor = document.getElementById("screenshot_section");
        // const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
        // window.scroll({
        //   top: offsetTop - 70,
        //   behavior: 'smooth'
        // });
        // }}
        />
      </Link>
    </Fragment>
  );
  return (
    <BackgroundImage
      Tag="section"
      style={{
        backgroundAttachment: "fixed",
      }}
      fluid={Data.BackgroundImage.childImageSharp.fluid}
    >
      <BannerWrapper id="banner_section">
        {/* <Particles /> */}
        <Container>
          <Box className="row" {...row}>
            <Box className="col" {...col}>
              <FeatureBlock
                title={
                  <Heading as="h1"
                    content="Helping your repair business grow."
                    {...title}
                  />
                }
                description={
                  <Text
                    content="Save time organizing your current tasks, insurer orders, repair history, invoices, and put this time in real business. Whether you are home appliances technician or a plumber."
                    {...description}
                  />
                }
                button={<ButtonGroup />}
              />
            </Box>
          </Box>
        </Container>
        <BannerObject>
          <div className="objectWrapper">
            <Img fluid={Data.BannerObject1.childImageSharp.fluid} alt="BannerObject" />
            <div className="dashboardWrapper">
              <AnchorLink href="#screenshot_section">
                <Img fluid={image.fluid} alt="Workplan Screen" />
              </AnchorLink>
            </div>
            {mobile && <div className="dashboardWrapperMobile">
              <AnchorLink href="#screenshot_section">
                <Img fluid={mobile.fluid} alt="Workplan Screen - Mobile" />
              </AnchorLink>
            </div>}
          </div>
        </BannerObject>
      </BannerWrapper>
    </BackgroundImage>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: ['16px', '18px', '20px'],
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#008abe',
    ml: '18px',
    colors: 'primary',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
