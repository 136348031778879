import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import styled, { createGlobalStyle } from "styled-components"

import { imac } from "react-icons-kit/ikons/imac"
import { iosBookOutline } from 'react-icons-kit/ionicons/iosBookOutline'
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';

import SectionHeading from "../SectionHeading"
import SectionWrapper from './screenShots.style';

const GlobalStyle1 = createGlobalStyle`
#screenshot_section {
  .slide {
    padding: 0 15px 15px 15px;
    background: initial !important;
    p {
      font-size: 1.5em;
      color: #008abe;
    }
  }

  .carousel-slider {
    overflow: visible !important;
  }

  .control-arrow {
    background-color: rgba(0,138,190,0.9) !important;
    width: 50px;
    height: 50px;
    top: 50% !important;
    bottom: 50% !important;
    transform: translate(0,-50%);
    border-radius: 10px;
  }
  .control-prev {
    left: -20px !important;
  }
  .control-next {
    right: -20px !important;
  }
  .control-dots {
    position: inherit !important;
    .dot {
      padding: 10px;
      background-color: #008abe !important;
    }
  }

  .ril__toolbarLeftSide {
    @media (max-width: 768px) {
      padding-left: 5px;
    }
  }

  .gatsby-image-wrapper {
    box-shadow: rgba(27, 67, 111, 0.25) 0px 5px 15px 0px;
  }
}
`

const DemoButton = styled(Button)`
  @media (max-width: 768px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .btn-text {
    @media (max-width: 768px) {
      text-indent: -9999px;
      padding: 0 !important;
    }
  }
}
`

const getLargestImage = srcSet => {
  const sets = srcSet.split(",")
  if (sets.length > 0) {
    const largest = sets[sets.length - 1]
    const src = largest.split(" ")[0]
    return src
  }
}

const UpdateScreen = ({ button }) => {
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const Data = useStaticQuery(graphql`
  query {
    saasJson {
      SCREENSHOTS {
        title
        demourl
        docsurl
        image {
          childImageSharp {
            fluid (maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`);

  return (
    <SectionWrapper id="screenshot_section">
      <GlobalStyle1 />
      {open && <Lightbox
        mainSrc={getLargestImage(Data.saasJson.SCREENSHOTS[index].image.childImageSharp.fluid.srcSet)}
        nextSrc={getLargestImage(Data.saasJson.SCREENSHOTS[(index + 1) % Data.saasJson.SCREENSHOTS.length].image.childImageSharp.fluid.srcSet)}
        prevSrc={getLargestImage(Data.saasJson.SCREENSHOTS[(index + Data.saasJson.SCREENSHOTS.length - 1) % Data.saasJson.SCREENSHOTS.length].image.childImageSharp.fluid.srcSet)}
        onCloseRequest={() => setOpen(false)}
        onMovePrevRequest={() => setIndex((index + Data.saasJson.SCREENSHOTS.length - 1) % Data.saasJson.SCREENSHOTS.length)}
        onMoveNextRequest={() => setIndex((index + 1) % Data.saasJson.SCREENSHOTS.length)}
        imageTitle={<div style={{ display: "flex" }}>
          <span>{Data.saasJson.SCREENSHOTS[index].title}</span>
          {Data.saasJson.SCREENSHOTS[index].demourl &&
            <a href={Data.saasJson.SCREENSHOTS[index].demourl}>
              <DemoButton
                {...button}
                style={{ marginLeft: 15, marginTop: 2 }}
                colors="primaryWithBg"
                variant="extendedFab"
                title="Open In Demo"
                iconPosition="left"
                icon={<Icon icon={imac} size={32} />}
                aria-label="open in demo"
              />
            </a>
          }
          {Data.saasJson.SCREENSHOTS[index].docsurl &&
            <a href={Data.saasJson.SCREENSHOTS[index].docsurl}>
              <DemoButton
                {...button}
                style={{ marginLeft: 5, marginTop: 2 }}
                colors="primaryWithBg"
                variant="extendedFab"
                title="Documentation"
                iconPosition="left"
                icon={<Icon icon={iosBookOutline} size={32} />}
                aria-label="open documentation"
              />
            </a>
          }
        </div>}
        reactModalStyle={{
          content: {
            zIndex: 2000,
          }
        }}
      />
      }
      <Container>
        <Zoom>
          <SectionHeading
            slogan="PRODUCT SCREENSHOT"
            title="See some important parts of our system"
          />
        </Zoom>
        <Fade up delay={100}>
          <Carousel
            onClickItem={(index) => {
              setIndex(index)
              setOpen(true)
            }}
            useKeyboardArrows
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={3000}
            showThumbs={false}
            stopOnHover={false}
          >
            {Data.saasJson.SCREENSHOTS.map((item, index) => (
              <div key={index}>
                <p>{item.title}</p>
                <Img draggable={false} fluid={item.image.childImageSharp.fluid} alt={item.title} />
              </div>
            ))}
          </Carousel>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

UpdateScreen.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
  button: {
    pl: '22px',
    pr: '22px',
  },
};

export default UpdateScreen;
