import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Icon } from "react-icons-kit"
import { quotesLeft } from 'react-icons-kit/icomoon/quotesLeft'
import { arrowLeft2 } from 'react-icons-kit/icomoon/arrowLeft2'
import { arrowRight2 } from 'react-icons-kit/icomoon/arrowRight2'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import TestimonialSectionWrapper, {
  TextWrapper,
  ImageWrapper,
  RoundWrapper,
  ClientName,
  ArrowWrapper,
} from './testimonialSection.style';

const TestimonialSection = ({
  sectionSubTitle,
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Testimonial {
          id
          name
          comment
          designation
          avatar_url {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  // Glide js options
  const glideOptions = {
    type: 'carousel',
    autoplay: 4000,
    perView: 1,
  };

  if (Data.saasJson.Testimonial.length < 2) {
    glideOptions.autoplay = false
  }

  return (
    <TestimonialSectionWrapper id="testimonial_section">
      <Fade up delay={100}>
        <Container>
          <Text content="CLIENT COMMENTS" {...sectionSubTitle} />
          <Carousel
            className="glide"
            useKeyboardArrows
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            autoPlay={Data.saasJson.Testimonial.length > 1}
            infiniteLoop
            interval={3000}
            showThumbs={false}
            stopOnHover={false}
          >
            {Data.saasJson.Testimonial.map((item, index) => (
              <div key={index} className="glide__slide" >
                <TextWrapper>
                  <Icon icon={quotesLeft} size={24} />
                  <Text content={item.comment} {...commentStyle} />
                  <ClientName>
                    <Heading content={item.name} {...nameStyle} />
                    <Heading
                      content={item.designation}
                      {...designationStyle}
                    />
                  </ClientName>
                </TextWrapper>
                <ImageWrapper>
                  <RoundWrapper>
                    <Image
                      fluid={item.avatar_url.childImageSharp.fluid}
                      alt={item.name}
                    />
                  </RoundWrapper>
                </ImageWrapper>
              </div>
            ))}
          </Carousel>
          <ArrowWrapper >
            <Button
              icon={<Icon icon={arrowLeft2} size={24} />}
              variant="textButton"
              aria-label="prev"
              {...btnStyle}
            />
            <Button
              icon={<Icon icon={arrowRight2} size={24} />}
              variant="textButton"
              aria-label="next"
              {...btnStyle}
            />
          </ArrowWrapper>
        </Container>
      </Fade>
    </TestimonialSectionWrapper >
  );
};

// TestimonialSection style props
TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

// TestimonialSection default style
TestimonialSection.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#008abe',
    mb: '20px',
    ml: 'auto',
    mr: 'auto',
    pl: '12px',
    maxWidth: '954px',
  },
  // client comment style
  commentStyle: {
    color: '#0f2137',
    fontWeight: '400',
    fontSize: ['22px', '22px', '22px', '30px'],
    lineHeight: '1.72',
    mb: '47px',
  },
  // client name style
  nameStyle: {
    as: 'h3',
    color: '#008abe',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '30px',
    mb: 0,
  },
  // client designation style
  designationStyle: {
    as: 'h5',
    color: 'rgba(52, 61, 72, 0.8)',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    ml: ['0', '10px'],
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: 'absolute',
    bottom: '62px',
    left: '12px',
  },
  // next / prev btn style
  btnStyle: {
    minWidth: 'auto',
    minHeight: 'auto',
    mr: '13px',
    fontSize: '16px',
    color: '#343d484d',
  },
};

export default TestimonialSection;
