import React, { Fragment, useRef, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga'
import { graphql } from 'gatsby';

import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection';
import Service from '../containers/Saas/Service';
// import VisitorSection from '../containers/Saas/VisitorSection';
// import ServiceSection from '../containers/Saas/ServiceSection';
import FaqSection from '../containers/Saas/FaqSection';
import Footer from '../containers/Saas/Footer';
import PricingSection from '../containers/Saas/PricingSection';
// import TrialSection from '../containers/Saas/TrialSection';
// import TimelineSection from '../containers/Saas/TimelineSection';
// import UpdateScreen from '../containers/Saas/UpdateScreen';
import ScreenShots from '../containers/Saas/ScreenShots';
import TestimonialSection from '../containers/Saas/TestimonialSection';
// import PartnerSection from '../containers/Saas/PartnerSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import useOnScreen from "common/src/hooks/useOnScreen"

const ScreenReveal = ({ onShow, children }) => {
  const ref = useRef()
  const onScreen = useOnScreen(ref, "-350px")
  const showed = useRef()
  useEffect(() => {
    if (onScreen && !showed.current) {
      showed.current = true
      onShow()
    }
  }, [onScreen])
  return <div ref={ref}>{children}</div>
}

export default ({ data }) => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="RepairSetup - Helping your repair business grow"
          keywords={["technician", "repair", "appliance", "plumber", "handyman", "ORHP", "Cinch", "HSA", "AHS", "field", "software", "system", "FSM"]}
          description="Homepage for RepairSetup field technician system"
          image={data.BannerObject2.childImageSharp.original.src}
          alt="RepairSetup Banner image"
          imageWidth={data.BannerObject2.childImageSharp.original.width}
          imageHeight={data.BannerObject2.childImageSharp.original.height}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={1000} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection image={data.BannerObject2.childImageSharp} mobile={data.BannerObjectMobile.childImageSharp} />
          <ScreenReveal onShow={() => {
            console.log("Features")
            ReactGA.event({
              category: 'view',
              action: 'Features'
            })
          }}>
            <Service />
          </ScreenReveal>
          {/* <VisitorSection /> */}
          {/* <ServiceSection /> */}
          <ScreenReveal onShow={() => {
            console.log("Pricing")
            ReactGA.event({
              category: 'view',
              action: 'Pricing'
            })
          }}>
            <PricingSection />
          </ScreenReveal>
          <ScreenReveal onShow={() => {
            console.log("Screenshots")
            ReactGA.event({
              category: 'view',
              action: 'Screenshots'
            })
          }}>
            <ScreenShots />
          </ScreenReveal>
          <TestimonialSection />
          {/* <PartnerSection /> */}
          {/* <TimelineSection /> */}
          <ScreenReveal onShow={() => {
            console.log("FAQ")
            ReactGA.event({
              category: 'view',
              action: 'FAQ'
            })
          }}>
            <FaqSection />
          </ScreenReveal>
          {/* <TrialSection /> */}
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export const query = graphql`
query {
  BannerObject2 : file(relativePath: { eq: "image/saas/screenshots/workplan.png" }) {
    childImageSharp {
      original {
        width
        height
        src
      }
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  BannerObjectMobile : file(relativePath: { eq: "image/saas/screenshots/workplan_m.png" }) {
    childImageSharp {
      original {
        width
        height
        src
      }
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`