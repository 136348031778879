import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';

const BlogPost = ({ className, thumbUrl, title, excerpt, link }) => {
  // Add all classes to an array
  const addAllClasses = ['blog_post'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }
  return (
    <Link to={link}>
      <div className={addAllClasses.join(' ')} style={{ cursor: link ? "pointer" : "initial" }}>
        <div className="thumbnail">
          <img src={thumbUrl} alt={title} style={{ marginRight: 10, width: "fit-content", height: "fit-content", marginTop: "auto", marginBottom: "auto" }} />
          <h3 className="title">{title}</h3>
        </div>
        <div className="content">
          <p className="excerpt">{excerpt}</p>
          {link && <div className="learn_more">
            Learn More <Icon icon={chevronRight} />
          </div>}
        </div>
      </div>
    </Link>
  );
};

BlogPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.element,
  link: PropTypes.string,
};

export default BlogPost;
