import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import ReactGA from 'react-ga'

import FaqSectionWrapper from './faqSection.style';
import SectionHeading from "../SectionHeading"

const FaqSection = ({
  titleStyle,
  descriptionStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Faq {
          id
          title
          description
          expend
        }
      }
    }
  `);

  return (
    <FaqSectionWrapper id="faq_section">
      <Container noGutter>
        <Zoom>
          <SectionHeading
            slogan="FAQ"
            title="Want to ask something about us ?"
          />
        </Zoom>
        <Box className="row">
          <Accordion onChange={(items) => {
            ReactGA.event({
              category: 'view',
              action: `FAQ-${items[0]}`
            })
          }}>
            <Fragment>
              {Data.saasJson.Faq.map((faqItem, index) => (
                <Fade up delay={100 * (1 + index)} key={faqItem.id}>
                  <AccordionItem key={index} expanded={faqItem.expend}>
                    <Fragment>
                      <AccordionTitle>
                        <Fragment>
                          <Heading content={faqItem.title} {...titleStyle} />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={minus} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={plus} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </Fragment>
                      </AccordionTitle>
                      <AccordionBody>
                        <Text
                          // content={faqItem.description}
                          dangerouslySetInnerHTML={{ __html: faqItem.description }}
                          {...descriptionStyle}
                        />
                      </AccordionBody>
                    </Fragment>
                  </AccordionItem>
                </Fade>
              ))}
            </Fragment>
          </Accordion>
        </Box>
      </Container>
    </FaqSectionWrapper >
  );
};

// FaqSection style props
FaqSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// FaqSection default style
FaqSection.defaultProps = {
  // accordion title default style
  titleStyle: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: ['15px', '15px', '16px', '17px'],
    color: '#5d646d',
    lineHeight: '1.75',
    mb: '0',
    fontWeight: '400',
  },
};

export default FaqSection;
